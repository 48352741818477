

    import { defineComponent, PropType } from 'vue'

    export default defineComponent({
        name: 'CheckboxInput',
        props: {
            title: { type: String as PropType<string>, required: true },
            checked: { type: Boolean as PropType<boolean>, default: false }
        }
    })

